#auth-layout {
  height: 100%;

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $color-secondary-blue;

    .back-to-site {
      position: absolute;
      top: 30px;
      right: 30px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-left: 10px;
      font-family: "Montserrat-Bold";
      svg{
        margin-right: 10px;
      }
    }
  }
  .content-auth{
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-layout{
      // width: 400px;
      .title-auth{
        margin-bottom: 45px;
      }
      .footer-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .wrap-help{
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        font-size: 18px;
        .help-service{
          width: 400px;
          font-family: Montserrat-Bold;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          a{
            border: 1px solid $dark-blue;
            padding: 10px;
            border-radius: 20px;
            margin-left: 60px;
            font-size: 14px;
          }
        }
      }
    }


  }
}
