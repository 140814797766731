.chat-list {
    .rce-citem-body--top-title {
        font-weight: 600;
        font-size: 14px;
    }

    .rce-citem-body--bottom-title {
        font-size: 14px;
    }
}

.wrapper-itinerary-sendmail {
    .included-view {
        line-height: 25px;
        padding-left: 25px;
        ul {
            flex: 1;
            list-style-type: disc !important;
            padding-left: 50px;
            li {
                display: list-item !important;
                font-size: 18px !important;
                div, span {
                    font-size: 18px !important;
                }
            }
        }
        ol {
            padding-left: 50px;

            li {
                display: list-item !important;
                font-size: 18px !important;
                div, span {
                    font-size: 18px !important;
                }
            }
        }
        p {
            font-size: 18px !important;
            margin-bottom: 0px;
        }

        .split-golf-content {
            border: none !important;
        }
        // .golf-title {
        //     display: none;
        // }
    }

    .overview-sendmail {
        p {
            margin-bottom: 10px;
            font-size: 18px !important;
            line-height: 30px;
        }
    }

    .preview-content {
        line-height: 25px;

        p {
            margin-bottom: 10px;
        }



    }

}

.swiper-button-prev,
.swiper-button-next {
    color: #fff !important;
}

.box-itinerary-book {
    .select-room {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        background-color: #205682;
        text-align: center;
        font-weight: 600;
        border-radius: 10px 0px 0px 10px;
    }

    .MuiButtonGroup-middleButton {
        border-right: none !important;
        border-color: transparent !important;
    }

    .MuiIconButton-root {
        background-color: #205682dc !important;
        color: #fff;

        &.Mui-disabled {
            background-color: #fff !important;
        }
    }
}
