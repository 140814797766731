@import "variable";
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700&display=swap");

@each $size in $text-sizes {
  .text-#{$size} {
    font-size: #{$size}px !important;
  }
  .w-#{$size} {
    width: #{$size}px !important;
  }
  .h-#{$size} {
    height: #{$size}px !important;
  }
  .min-h-#{$size} {
    min-height: #{$size}px !important;
  }
  .min-w-#{$size} {
    min-width: #{$size}px !important;
  }
  .max-w-#{$size} {
    max-width: #{$size}px !important;
  }
  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .px-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }
  .py-#{$size} {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }
  .p-#{$size} {
    padding: #{$size}px !important;
  }
  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .mx-#{$size} {
    margin-left: #{$size}px !important;
    margin-right: #{$size}px !important;
  }
  .my-#{$size} {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }
  .m-#{$size} {
    margin: #{$size}px !important;
  }
  .rounded-#{$size} {
    border-radius: #{$size}px !important;
  }
  .rounded-#{$size}-top {
    border-top-left-radius: #{$size}px !important;
    border-top-right-radius: #{$size}px !important;
  }
  .rounded-#{$size}-bottom {
    border-bottom-left-radius: #{$size}px !important;
    border-bottom-right-radius: #{$size}px !important;
  }
  .rounded-#{$size}-left {
    border-top-left-radius: #{$size}px !important;
    border-bottom-left-radius: #{$size}px !important;
  }
  .rounded-#{$size}-right {
    border-top-right-radius: #{$size}px !important;
    border-bottom-right-radius: #{$size}px !important;
  }
  .flex-#{$size} {
    flex: #{$size} !important;
  }
  .gap-#{$size} {
    gap: #{$size}px !important;
  }
  .zIndex-#{$size} {
    z-index: #{$size} !important;
  }
  .top-#{$size} {
    top: #{$size}px !important;
  }
  .right-#{$size} {
    right: #{$size}px !important;
  }
  .bottom-#{$size} {
    bottom: #{$size}px !important;
  }
  .left-#{$size} {
    left: #{$size}px !important;
  }
}

.d {
  &-flex {
    display: flex !important;
  }
  &-block {
    display: block !important;
  }
  &-content {
    display: contents !important;
  }
  &-inline {
    display: inline !important;
  }
  &-none {
    display: none !important;
  }
}

.justify {
  &-between {
    justify-content: space-between !important;
  }
  &-around {
    justify-content: space-around !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-start {
    justify-content: flex-start !important;
  }
}
.align {
  &-center {
    align-items: center !important;
  }
  &-end {
    align-items: flex-end !important;
  }
  &-start {
    align-items: flex-start !important;
  }
}
.w {
  &-full {
    width: 100% !important;
  }
  &-half {
    width: 50% !important;
  }
  &-quarter {
    width: 25% !important;
  }
}

.h {
  &-full {
    height: 100% !important;
  }
  &-half {
    height: 50% !important;
  }
  &-71 {
    height: 71px !important;
  }
  &-quarter {
    height: 25% !important;
  }
  &-auto {
    height: auto !important;
  }
  &-fit {
    height: fit-content !important;
  }
}

.fw {
  &-300 {
    font-weight: 300 !important;
  }
  &-400 {
    font-weight: 400 !important;
  }
  &-500 {
    font-weight: 500 !important;
  }
  &-600 {
    font-weight: 600 !important;
  }
  &-700 {
    font-weight: 700 !important;
  }
  &-900 {
    font-weight: 900 !important;
  }
}

.border {
  &-full {
    border: 1px solid $color-border !important;
  }
  &-none {
    border: none !important;
  }
  &-danger {
    border: 1px solid $color-danger !important;
  }
  &-top {
    border-top: 1px solid $color-border !important;
  }
  &-left {
    border-left: 1px solid $color-border !important;
  }
  &-right {
    border-right: 1px solid $color-border !important;
  }
  &-bottom {
    border-bottom: 1px solid $color-border !important;
  }
  &-color-primary {
    border-color: $tkg-blue !important;
  }
}
.text {
  &-right {
    text-align: right !important;
  }
  &-left {
    text-align: left !important;
  }
  &-center {
    text-align: center !important;
  }
  &-bold {
    font-weight: 600 !important;
  }
  &-capitalize {
    text-transform: capitalize !important;
  }
  &-uppercase {
    text-transform: uppercase !important;
  }
  &-lowercase {
    text-transform: lowercase !important;
  }
  &-white {
    color: white !important;
  }
  &-danger {
    color: $color-danger !important;
  }
  &-tkg-blue {
    color: $tkg-blue !important;
  }
  &-ellipsis {
    // white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &-info {
    color: $color-info !important;
  }
  &-dark {
    color: #000 !important;
  }
}

.bg {
  &-main {
    background-color: $background-color !important;
  }
  &-light-blue {
    background-color: $light-blue !important;
  }
  &-color-save-button {
    background-color: $color-save-button !important;
  }
  &-slight-blue {
    background-color: $slight-blue !important;
  }
  &-tkg-blue {
    background-color: $tkg-blue !important;
  }
  &-transparent {
    background-color: transparent !important;
  }
  &-orange {
    background-color: $orange !important;
  }
}
.overflow {
  &-scroll {
    overflow: scroll !important;
  }
  &-auto {
    overflow: auto !important;
  }
  &-hidden {
    overflow: hidden !important;
  }
  &-visible {
    overflow: visible !important;
  }
}
.overflowY {
  &-scroll {
    overflow: scroll !important;
  }
  &-auto {
    overflow: auto !important;
  }
  &-hidden {
    overflow: hidden !important;
  }
  &-visible {
    overflow: visible !important;
  }
}
.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-default {
    cursor: default;
  }
  &-not-allowed {
    cursor: not-allowed;
  }
  &-zoom-in {
    cursor: zoom-in;
  }
  &-zoom-out {
    cursor: zoom-out;
  }
  &-grab {
    cursor: grab;
  }
  &-grabbing {
    cursor: grabbing;
  }
}
.position {
  &-absolute {
    position: absolute !important;
  }
  &-relative {
    position: relative !important;
  }
  &-fixed {
    position: fixed !important;
  }
  &-sticky {
    position: sticky !important;
  }
}
.popper-in-modal {
  z-index: 9999 !important;
}
.MuiFormControl-root {
  margin-bottom: 10px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.height-100vh {
  height: 100vh;
}
.height-100 {
  height: 100%;
}

img {
  max-width: 100%;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
body {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: var(--text-14) !important;
  background-color: $background-color !important;
  color: $dark-blue !important;
}
* {
  font-family: "Nunito Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif !important;
}
.tox-statusbar__branding {
  display: none !important;
}
.manual-button {
  min-width: 150px !important;
  background-color: $primary-blue !important;
  color: white !important;
  font-family: "Nunito Sans", sans-serif !important;
  padding: 6px 15px !important;
  text-transform: inherit !important;
  &:hover {
    background-color: $color-secondary-blue !important;
  }
  &:disabled {
    opacity: 0.8 !important;
    cursor: wait !important;
  }
}
.MuiLoadingButton-loading {
  padding-left: 25px !important;
}

.button-gray {
  background-color: gray !important;
  color: white !important;
  &:hover {
    background-color: #38383a !important;
  }
}
.button-orange {
  background-color: $orange !important;
  color: white !important;
  border: 1px solid $orange !important;
  &:hover {
    background-color: #fff !important;
    color: $orange !important;
  }
}
.button-cancel {
  background-color: #6e7881 !important;
  color: white !important;
  // border: 1px solid #6e7881 !important;
  &:hover {
    background-color: #525a61 !important;
    color: #fff !important;
  }
}
a {
  text-decoration: none;
  color: $dark-blue;
}

.MuiInputBase-root {
  height: 100%;
  border-radius: 8px !important;
  .MuiInputBase-input {
    line-height: 1.5 !important;
    font-size: 0.85rem !important;
    border-radius: 8px !important;
    &.Mui-disabled {
      background-color: rgba(128, 128, 128, 0.1019607843) !important;
    }
  }
  .MuiInputBase-inputSizeSmall {
    height: 45px !important;
    padding: 0px 10px !important;
  }
  &.MuiAutocomplete-inputRoot {
    padding: 0 !important;
  }
}
.MuiInputAdornment-positionStart {
  &.price {
    margin-right: 14px !important;
  }
}
.bg-readonly input {
  -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
  background: #e3e3e39e !important;
}
.bg-readonly .MuiSelect-select {
  -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
  background: #e3e3e39e !important;
}
.MuiSpeedDialAction-staticTooltipLabel {
  width: max-content !important;
}
.readonly-input {
  .MuiInputAdornment-root {
    &.end {
      margin: 0 !important;
    }
  }
  .MuiInputBase-root {
    padding-right: 0 !important;
  }
}
.bg-datepicker-readonly {
  -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
  background: #e3e3e39e !important;
  input {
    -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
    background: unset !important;
  }
}
.bg-multi-select-readonly div {
  -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
  background: #e3e3e39e !important;
  border-radius: 8px;
  input {
    -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
    background: unset !important;
  }
}

.date-range > div {
  height: 45px !important;
  .MuiFormLabel-root.MuiInputLabel-root {
    color: #00000099 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #0000003b !important;
  }
}
.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
  display: flex;
  align-items: center;
}
.resizer {
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    border-top: 8px solid #1c87c7;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    -webkit-transform: rotate(-45deg);
    z-index: 1;
    opacity: 0.5;
    position: absolute;
    bottom: 1px;
    right: -3px;
    pointer-events: none;
  }
}

.MuiTabs-flexContainer {
  .MuiButtonBase-root {
    min-height: 35px !important;
  }
  .Mui-selected {
    border-radius: 8px !important;
  }
  .MuiTabs-indicator {
    height: 0px !important;
  }
}
.tab-has-color {
  .Mui-selected {
    background-color: #103551;
    color: white !important;
    border-radius: 0 !important;
  }
}
* {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(black, 0.3);
    border-radius: 12px;
  }
}
.swal2-container {
  z-index: 9000 !important;
}

.bg-color-main {
  background-color: $primary-blue !important;
}

.section-btn-add {
  background: #fff !important;
  border-radius: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 10px !important;
}

.section-search {
  -webkit-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  background: #fff !important;
  border-radius: 16px !important;
  margin-left: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 32px !important;
}

.header-component {
  padding: 20px !important;
  background: $secondary-color-dark-sea !important;
  margin-bottom: 20px !important;
}

.icon-dropdown {
  height: 2em !important;
  width: max-content !important;
  padding-right: 0.5em !important;
  position: absolute !important;
  right: 0 !important;
}

.body-content {
  background: #fff !important;
  padding: 16px !important;
  border-radius: 12px !important;
  // height: 100% !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  .app-content {
    padding-bottom: 0 !important;
    height: 100% !important;
    overflow-y: hidden !important;
    .simplebar-wrapper {
      display: contents !important;
      .simplebar-mask {
        height: -webkit-fill-available !important;
        .simplebar-offset {
          height: 100% !important;
          .simplebar-content-wrapper {
            height: inherit !important;
            .MuiTableContainer-root {
              height: inherit !important;
              overflow: scroll !important;
            }
          }
        }
      }
    }
  }
}

.color-icon {
  color: $secondary-color-dark-sea !important;
}

.btn-tkg {
  background-color: $secondary-color-dark-sea !important;
}
.text-color-danger {
  color: $color-danger !important;
}
.bg-color-danger {
  background-color: #fdeded !important;
}
.btn-add {
  background: $secondary-color-dark-sea;
  text-align: center;
  border-radius: 50%;
  padding: 6px;
  height: 35px;
  width: 35px;
  margin-top: 5px;
  cursor: pointer;
}

.MuiFormControl-root {
  &.minus {
    input {
      color: red;
    }
  }
  &.plus {
    input {
      color: green;
    }
  }
}

.tax-field {
  width: 80px;
  margin-bottom: 0 !important;
  margin-right: 10px !important;
  border: none !important;
  .MuiInputBase-input {
    color: blue;
    border: none;
    border-radius: unset !important;
  }
  &.single {
    margin-right: 0 !important;
    .MuiOutlinedInput-notchedOutline {
      border-right: unset !important;
      border-bottom: unset !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-right: 1px solid #d5d5d5;
    border-radius: 0;
    border-top: unset;
  }
}
.dialog {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px;
    background-color: $secondary-color-dark-sea;
    color: #fff;
  }
  &-content {
    background-color: $secondary-color-light-grey;
    padding: 16px;
    overflow: unset;
    height: calc(100% - 200px);
  }
}
.has-price {
  background-color: #def5ff !important;
}

.card-dashboard {
  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 24px 0px rgb(13, 89, 130);
    -moz-box-shadow: 0px 0px 24px 0px rgb(13, 89, 130);
    box-shadow: 0px 0px 24px 0px rgb(13, 89, 130);
  }
}

.tooltipCustom {
  &.fit-content {
    width: fit-content;
  }
  &:hover {
    cursor: pointer;
  }
  &.text {
    &:hover {
      color: blue;
    }
  }
}
.editor-validate {
  border-color: $color-danger !important;
}
.wrapper-validate {
  border: 1px solid $color-danger !important;
  border-color: $color-danger !important;
  border-radius: 8px;
}
.MuiPaginationItem-root.Mui-selected {
  background-color: #fff !important;
  border: 1px solid $secondary-color-dark-sea !important;
  border-radius: 7px !important;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}
.drawer-common .MuiDrawer-paper {
  width: calc(100vw - 280px);
}
.drawer-small .MuiDrawer-paper {
  width: calc(100vw - 30%);
}
.drawer-smaller .MuiDrawer-paper {
  width: calc(100vw - 50%);
}
.timeline-left {
  &::before {
    flex: 0 !important;
  }
}
.AdjustNumber {
  .MuiInputBase-input {
    text-align: center;
    border-right: 1px solid #ccc;
    border-radius: unset !important;
  }
}
.form-input-white {
  input {
    background-color: white;
  }
}
.preview-content {
  padding-left: 50px;
  line-height: 30px;
  p,
  li {
    font-size: 16px;
  }
  a {
    text-decoration: underline;
  }
}
.button-reverse {
  background-color: red !important;
  color: white !important;
  &:hover {
    background-color: white !important;
    color: red !important;
    border: 1px solid red;
  }
}
.switch-yes-no {
  width: 65px !important;
  padding: 6px !important;
  .MuiButtonBase-root.MuiSwitch-switchBase {
    transform: translateX(1px) !important;
  }
  .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
    color: #fff !important;
    transform: translateX(26px) !important;
  }
  .MuiSwitch-track {
    border-radius: 20px !important;
    background-color: #818181;
    opacity: 1 !important;
    &:after,
    &:before {
      color: white !important;
      font-size: 11px !important;
      position: absolute !important;
      top: 13px !important;
    }
    &:after {
      content: "Yes";
      left: 13px !important;
    }
    &:before {
      content: "No";
      right: 12px !important;
    }
  }
}
.d-none {
  display: none;
}
.user-action {
  font-size: 12px !important;
  font-style: italic;
}
.Mui-readOnly.MuiInputBase-formControl {
  background-color: #8080801a !important;
}
.AdjustNumber {
  .Mui-readOnly.MuiInputBase-formControl {
    background-color: white !important;
  }
}
.input-price-readonly {
  .MuiInputBase-root {
    background-color: white;
    padding-right: 0 !important;
  }
}
.price-input {
  .MuiInputBase-root {
    background-color: white;
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
  .tax-field {
    .MuiInputBase-root {
      min-width: 65px;
      padding-left: 0 !important;
    }
  }
  .price-input {
    margin-bottom: 0 !important;
    .MuiInputBase-root {
      padding-left: 0 !important;
      fieldset {
        border-radius: 0 !important;
      }
      .MuiInputBase-input {
        border: unset !important;
        border-radius: 0 !important;
      }
    }
  }
}
.MuiTypography-root.MuiTreeItem-label {
  flex: 1;
}
.switch-to-edit {
  padding: 6px !important;
  .MuiButtonBase-root.MuiSwitch-switchBase {
    transform: translateX(1px);
  }
  .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
    color: #fff;
    transform: translateX(26px) !important;
  }
  width: 90px !important;
  &.active {
    .MuiSwitch-switchBase {
      left: 20px !important;
    }
    width: 85px !important;
  }
}

.new {
  display: inline-block;
  background-color: green;
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 80%;
}

@import "authentication";
@import "chat";
@import "app_layout";
@import "base";
@import "dominic";
@import "tyler";
@import "nathan";
@import "format_editor";
