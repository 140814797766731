:root {
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 600;
    --extra-bold: 700;
    height: 100%;
  }
  
  body {
    line-height: 1.35 !important;
    height: 100%;
  }
  
  html {
    width: 100%;
    height: 100%;
  }
  
  #root {
    width: 100%;
    height: 100%;
  }
  
  * {
    margin: 0;
    padding: 0;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  iframe#webpack-dev-server-client-overlay{display:none!important}

  img {
    max-width: 100%;
    height: auto;
  }
  
  h1 {
    font-size: 22px;
    font-weight: 600;
  }
  
  h2 {
    font-size: 20px;
    font-weight: 500;
  }
  
  h3 {
    font-size: 18px;
    font-weight: 500;
  }
  
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
  
  h5 {
    font-size: 14px;
    font-weight: 500;
  }
  
  h6 {
    font-size: 12px;
    font-weight: 500;
  }
  
  p {
    font-size: 14px;
    font-weight: 400;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .input-radio {
    width: 20px;
    height: 20px;
  }
  
  .custom-row {
    padding: 5px 0;
    align-items: center;
  }
  
  .gray {
    background-color: var(--bs-secondary-bg-subtle);
  }
  
  #error-page {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  

  

  .red {
    color: red;
  }
  
  .blue {
    color: blue;
  }
  
  .choose-img {
    margin: auto;
    border-radius: 5px;
    box-shadow: 0px 10px 10px 0px #b8b8b8;
    cursor: n-resize;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  
  .choose-img:hover {
    position: relative;
    z-index: 9999;
    transform: scale(1.1);
  }
  
  .opacity-4 {
    opacity: 0.4;
    transition: 0s;
  }
  
  .choose-img>.img-layout {
    width: 100%;
    object-fit: cover;
    object-position: top;
    transition: 3s all ease;
  }
  
  .choose-img>.img-layout:hover {
    object-position: bottom;
  }
  
  .choose-img>.img-check {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 40%;
    top: 45%;
    z-index: 2;
  }
  
  .number-step {
    padding: 10px 18px;
    background: #cccccc70;
    color: #000;
    border-radius: 25px;
    width: 44px;
    height: 44px;
    font-size: 15px;
  }
  
  .number-step.active {
    padding: 10px 18px;
    background: #2dabe2;
    color: #fff;
    border-radius: 25px;
    width: 44px;
    height: 44px;
    font-size: 15px;
  }
  
  .line-step {
    width: 170px;
    background: #cccccc70;
    margin: 0 20px;
    height: 3px;
  }
  
  .line-step.active {
    width: 170px;
    margin: 0 20px;
    height: 3px;
    background: #2dabe2;
  }
  
  .loading {
    font-weight: 600;
    display: inline-block;
    clip-path: inset(0 1.2ch 0 0);
    animation: p 1s steps(4) infinite;
  }
  
  @keyframes p {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
  
  .swal2-container {
    z-index: 9999;
  }
  
  .select__control {
    border-radius: 8px !important;
  }
  
  .arco-trigger {
    z-index: 1300 !important;
  }
  
  .file-input {
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    font-size: 13px;
    opacity: 0;
    -moz-opacity: 0;
  }
  
  .relative {
    position: relative !important;
  }
  
  .absolute {
    position: absolute !important;
  }
  
  .h-50 {
    height: 50px !important;
  }
  
  .border-blue-1 {
    border: 1px solid #467ac1;
  }
  
  .text-color-main {
    color: #467ac1;
  }
  
  .px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    border-radius: 4px;
    width: max-content;
    transition: all .3s cubic-bezier(.165, .84, .44, 1);
  }
  
  .px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  
  .h-80 {
    height: 80px !important;
  }
  
  .size-40 {
    font-size: 40px;
  }
  
  .bg-white {
    background: #fff;
  }
  .bg-total-price {
    background: #dcf1f7;
  }
  
  .rounded-4 {
    border-radius: 4px;
  }
  
  .flex-center {
    justify-content: center;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .z-index-100 {
    z-index: 100;
  }
  
  .w-1\/1 {
    width: 100% !important;
  }
  
  .w-180 {
    width: 180px !important;
  }
  
  .h-180 {
    height: 180px !important;
  }

  /*Embed Responsive Classes*/
.cr-embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .cr-embed-responsive:before {
    display: block;
    content: '';
  }
  
  .cr-embed-responsive .cr-embed-responsive-item,
  .cr-embed-responsive iframe,
  .cr-embed-responsive embed,
  .cr-embed-responsive object,
  .cr-embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .cr-embed-responsive-21by9:before {
    padding-top: 42.85714286%;
  }
  
  .cr-embed-responsive-16by9:before {
    padding-top: 56.25%;
  }
  
  .cr-embed-responsive-4by3:before {
    padding-top: 75%;
  }
  
  .cr-embed-responsive-1by1:before {
    padding-top: 100%;
  }
  
  @media screen and (max-width: 499px) {
    .cr-embed-responsive-21by9:before,
    .cr-embed-responsive-16by9:before {
      padding-top: 100%;
    }
  }
  
  @media screen and (max-width: 1500px) {
    .table {
      display: block !important;
      width: 100%;
      overflow: auto
    };
  }
  

