$primary-blue: #0094d3;
$primary-gray: #38383a;
$dark-blue: #08263d;
$light-blue: rgba(49, 131, 171, 1);
$orange: #ff752b;
$background-color: #f4f8fb;
$slight-blue: #f4f7fe;
$tkg-blue: #174d75;
$color-secondary-blue: #0d5982;
$color-save-button: #2a8cb7;

// color in brand guide line
$primary-color-blue: #0395da;
$primary-color-dark: #424342;

$secondary-color-dark-sea: #174d76;
$secondary-color-sea: #2b8cb7;
$secondary-color-orange: #ff752c;
$secondary-color-blue: #0d5983;
$secondary-color-dark-blue: #0c2a42;
$secondary-color-light-grey: #f4f8fb;
$bg-form: #efecec;
$color-danger: #dc3545;
$color-border: #0000001f;
$color-info: #faad14;
$text-sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
  30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
  90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 110, 120, 130, 150, 200, 220, 250, 270, 300;
