.box {
    &-shadow-1 {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
&-unset {
    box-shadow: unset !important;
}
}

.curve-shape::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: attr(data-text);
    color: transparent;
    text-decoration-style: wavy;
    text-decoration-color: #2A3F51;
    text-decoration-line: underline;
}

.calendar-price {
    .rbc-toolbar-label {
        font-weight: 600 !important;
      }
      .rbc-current button {
        background: blue !important;
        color: #fff !important;
        border-radius: 50%;
        height: 25px !important;
        width: 25px !important;
        margin-top: 3px;
      }
    
      .rbc-today button {
        background: blue !important;
        color: #fff !important;
        border-radius: 40%;
        height: 20px !important;
        width: 45px !important;
        font-weight: 600;
        padding: 2px;
      }
      .rbc-row-segment .rbc-show-more {
        color: #063964 !important;
      }
      .rbc-off-range-bg {
        background: #ebebeb !important;
    }
}
.filtering  .rbc-time-view, .filtering .rbc-month-view {
  opacity: 0.5;
}

.textover1 {
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
.textover2 {
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
}
.textover3 {
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}

.excursionCard {
  &__image {
    border-radius: inherit;
    height: 260px;
  }
}

.pagination.-dots {
  display: flex;

  > * {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    background-color: currentColor;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
    transform: scale(0.75);
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: $primary-blue !important;
      transform: scale(1);
    }
  }
}

.arrow-left-hover {
  transition: all 0.3s ease-out;

  &:hover {
    color: $primary-blue;
    transform: translateX(-5px) scale(1.25);
  }
}
.arrow-right-hover {
  transition: all 0.3s ease-out;

  &:hover {
    color: $primary-blue;
    transform: translateX(5px) scale(1.25);
  }
}

.pagination.-buttons {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 40px;
  }

  .pagination {
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border-radius: 100%;
      background-color: white;
      color: $primary-blue;
      transition: all 0.3s ease-out;

      .icon {
        font-size: 12px;
      }

      &:hover {
        background-color: $primary-blue;
        color: white;
      }
    }

    &__count {
      > * {
        position: relative;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #d9d9d9
      }

      a {
        &::after {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 50%;
          width: 16px;
          height: 2px;
          transform: translateX(-50%);
          background-color: $primary-blue;
          transition: all 0.2s ease-out;
          opacity: 0;
        }

        &.-count-is-active {
          color: $primary-blue;

          &::after {
            opacity: 1;
          }
        }
      }
    }

  }
}

.pagination.-avatars {
  display: flex;
  align-items: center;

  .pagination {
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        border-radius: 100%;
        width: 92px;
        height: 92px;
        border: 2px solid $primary-color-dark;
        transition: all 0.3s ease-out;
        opacity: 0;
      }

      &.is-active {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .swiper-slide-thumb-active {
    .pagination {
      &__item {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .swiper-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
  .swiper-slide {
    width: auto !important;
  }
}
.group-button {
  z-index: 1;
}
.seeAll-button {
  &:hover {
    color: $tkg-blue !important;
    text-decoration: underline;
  }
}

.new-effect {
  position: relative;
  top: -3px;
  right: -3px;
  font-size: 14px;
  color: #f6fdff;
  background: #cf241d;
  font-weight: 600;
  animation: blinker 2s linear infinite;
  padding: 0 3px;
  border-radius: 3px;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}