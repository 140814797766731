.format-content {
    line-height: 25px;
    p {
        margin-bottom: 10px;
    }
}

.quill-custom {
    .ql-editor {
        resize: block;
        max-height: 500px;
    }
}