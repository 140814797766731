.box-loading-page {
  width: calc(100vw - 360px);
  overflow: hidden;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 82vh;
  background: #f5f5f5;
}
