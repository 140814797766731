.task-popup-container {
    position: relative;

    .task-popup {
        width: 380px;
        background: #e8f5ff;
        bottom: 15px;
        position: fixed;
        // border-radius: 10px;
        // box-shadow: 0px 25px 10px -15px #bfd2d580;
        box-shadow: 0px 1px 4px 1px rgb(191 210 213 / 50%);
        border-radius: 12px;
        border: 1px solid #174d7536;
        transition: 0.3s all;
        z-index: 1200;
        left: 15px;

        .task-popup-content {
            padding: 10px;
            overflow: hidden;
            overflow-y: scroll;
            height: 260px;
        }
    }

    .task-popup-icon {
        position: fixed;
        bottom: 15px;
        left: 15px;
        z-index: 1200;
    }
}

body div.se-container>input {
    border: 0;
    padding: 0;
    margin: -1px;
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip-path: inset(50%);
    white-space: nowrap;
    transform: scale(0);
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    font-weight: bold;
}
.text-time-animation {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-weight: 600 !important;
    &::before {
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        max-width: 7em;
        white-space: nowrap;
        color: #8cb900;
        animation: loading 3s linear infinite;
    }
}
.text-time-animation2 {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-weight: 600 !important;
    &::before {
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        max-width: 7em;
        white-space: nowrap;
        color: #174D75;
        animation: loading 3s linear infinite;
    }
}
@keyframes loading {
    0% {
        max-width: 0;
    }
}
// .styleViewOnly {
//     position: relative;
//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background: rgb(0 0 0 / 12%);
//         z-index: 1;
//         border-radius: 10px;
//         cursor: not-allowed;
//     }
// }