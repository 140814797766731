.MuiTreeItem-root{
  .MuiTreeItem-content{
    padding: 0.4375rem 0.3125rem !important;
    flex-direction: row-reverse;
    &:hover{
      background-color: #fff3 !important;
      border-radius: 10px;
    }
  }
}
.box-main{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.tree-category{
  .MuiTreeItem-root{
    .MuiTreeItem-content{
     
      flex-direction: row !important;
     
    }
  }
}